<template>
	<div class="hello">
		<div class="zhanweifu"></div>
		<div class="top_content">
			<div class="left_icon" @click="gotoback">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">编辑封面</div>
			<div class="save_btn" @click="sendurl">保存</div>
		</div>

		<div class="select_img" v-if="!fngmianlianjie">
			<img :src="imglist[imgindex]" alt="" />
		</div>

		<div class="select_img" v-if="fngmianlianjie">
			<img :src="fngmianlianjie" alt="" />
		</div>

		<div class="tablist">
			<div :class="tabindex == 0 ? 'item itemed' : 'item'" @click="tabindex = 0">视频帧</div>
			<div :class="tabindex == 1 ? 'item itemed' : 'item'" @click="tabindex = 1">相册上传</div>
		</div>

		<div class="xztupian" v-if="tabindex == 0">
			<div :class="imgindex == index ? 'tp_item tp_itemed' : 'tp_item'" v-for="(item, index) in imglist" @click="setindex(index)">
				<img :src="item" alt="" />
			</div>
		</div>

		<div class="sc_boxboxbox" v-if="tabindex == 1">
			<div class="imglistbox" v-if="coverurldata.length > 0">
				<div class="img_item">
					<div class="shanchuicon" @click="delcoverdata">
						<img style="width: 20px; height: 20px" src="../assets/delicon.png" alt="" />
					</div>
					<img :src="coverurldata" alt="" />
				</div>
			</div>

			<label class="topcontent">
				<input @change="handleimgfengmian" accept="image/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />
				<div class="uploadcontent">
					<div class="up_text">上传封面</div>
				</div>
			</label>
		</div>

		<div class="successtext" v-if="upsuccess">图片上传成功</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			uploadurl:'https://videoupload.appinfo.ltd',
			baseurl: 'https://api.admin91.top',
			tabindex: 0,
			coverurldata: [],
			imglist: '',

			UserId: '',
			Token: '',
			UserVid: '',
			imgindex: 0,
			fngmianlianjie: '',
			upsuccess: false
		};
	},

	mounted() {
		this.imglist = this.$route.params.imglist;
		this.UserId = this.$route.params.UserId;
		this.Token = this.$route.params.Token;
		this.UserVid = this.$route.params.UserVid;
	},
	methods: {
		//获取文件的md5值
		fileMd5Sum(file) {
			return new Promise((resolve) => {
				let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
				let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
				let fileReader = new FileReader();
				let Spark = new SparkMD5.ArrayBuffer();
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = function (e) {
					Spark.append(e.target.result);
					let md5 = Spark.end();
					let fileboxdata = {
						md5: md5,
						fileName: fileName,
						fileExtension: fileExtension
					};
					resolve(fileboxdata);
				};
			});
		},

		//大文件是否存在校验
		jianchefile(status, md5, uploadkey) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: status,
						md5: md5,
						uploadkey: uploadkey
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传图片文件
		upimgfile(md5, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', 0);
				formData.append('file', fileitem);
				axios.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//合并视屏切片
		joinvideo(md5, chunkslength, fileName, fileExtension) {
			let that = this;
			return new Promise((resolve, reject) => {
				let obj = {
					status: 'chunksMerge',
					md5: md5,
					chunks: chunkslength,
					name: md5,
					fileoldname: fileName,
					ext: fileExtension
				};
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunksMerge',
						md5: md5,
						chunks: chunkslength,
						name: md5,
						fileoldname: fileName,
						ext: fileExtension
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传封面图
		async upfengmiantu() {
			//上传封面图
			if (this.coverdata) {
				const imglistfile = this.coverdata;
				for (let index in imglistfile) {
					//获取图片md5信息
					let imgmd5mesgs = await this.fileMd5Sum(imglistfile[index]);
					//检测视频是否存在 不存在就上传
					let imgfileres = await this.jianchefile('md5Check', imgmd5mesgs.md5, 'sdqutyeksdbv855');
					//上传图片
					let upimgres = await this.upimgfile(imgmd5mesgs.md5, imglistfile[index]);
					//合并上传图片
					let upimgjieguo = await this.joinvideo(imgmd5mesgs.md5, 1, imgmd5mesgs.fileName, imgmd5mesgs.fileExtension);
					if (upimgjieguo.error == 'undefined' || upimgjieguo.error == undefined) {
						let imageURL = upimgjieguo.url;
						let imagestr = imageURL.split('/');
						let imageresult = '';
						for (let i = 3; i < imagestr.length; i++) {
							if (imageresult !== '') {
								imageresult += '/';
							}
							imageresult += imagestr[i];
						}
						this.fngmianlianjie = this.uploadurl+'/'+imageresult;
						this.upsuccess = true;
						setTimeout(() => {
							this.upsuccess = false;
						}, 2000);
					}
				}
			}
		},

		sendurl() {
			let imageURL;
			imageURL = this.fngmianlianjie == '' ? this.imglist[this.imgindex] : this.fngmianlianjie;
			let imagestr = imageURL.split('/');
			let imageresult = '';
			if (imagestr.length >= 3) {
				for (let i = 3; i < imagestr.length; i++) {
					if (imageresult !== '') {
						imageresult += '/';
					}
					imageresult += imagestr[i];
				}
			}
			this.$router.replace({
				name: 'uploadvideo',
				params: {
					tupianlianjie: imageresult
				}
			});
		},

		setindex(e) {
			this.imgindex = e;
		},

		//删除封面图
		delcoverdata() {
			this.coverdata = [];
			this.coverurldata = [];
		},
		//获取封面文件
		handleimgfengmian(e) {
			const URL = window.URL || window.webkitURL; // 当前地址
			this.coverdata = [];
			this.coverdata.push(e.target.files[0]);
			this.coverurldata = URL.createObjectURL(e.target.files[0]);

			this.upfengmiantu();
		},

		//返回
		gotoback() {
			this.$router.back();
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.hello {
	padding-top: 70px;
	.inputbox .item {
		padding: 10px 0px 5px 0px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
.zhanweifu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 40px;
	background-color: #fff;
	z-index: 9999;
}
.hello .top_content {
	position: fixed;
	left: 0;
	right: 0;
	top: 35px;
	background-color: #fff;
	z-index: 999;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
}
.hello .left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.hello .left_icon img {
	width: 20px;
	height: 20px;
}
.hello .center_content {
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.hello .save_btn {
	width: 60px;
	height: 30px;
	border-radius: 5px;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	white-space: nowrap;
	background-color: skyblue;
	color: #fff;
	text-align: center;
	line-height: 30px;
}

.select_img {
	width: 140px;
	height: 200px;
	margin: 10px auto;
}
.select_img img {
	width: 140px;
	height: 200px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-radius: 5px;
}

.tablist {
	display: flex;
	height: 40px;
	border-bottom: 1px solid #eee;
}
.tablist .item {
	font-size: 14px;
	color: #999;
	width: 50%;
	text-align: center;
	line-height: 40px;
}

.tablist .itemed {
	font-weight: bold;
	color: #000;
	font-size: 16px;
}

.xztupian {
	padding-top: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
.xztupian .tp_item {
	width: 17%;
	height: 100px;

	margin-bottom: 10px;
	border: 2px solid #fff;
}

.xztupian .tp_itemed {
	border: 2px solid red;
	border-radius: 5px;
}

.xztupian .tp_item img {
	width: 100%;
	height: 100px;
	border-radius: 5px;
}
.sc_boxboxbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}
.imglistbox .img_item {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
}
.imglistbox .img_item img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}
.shanchuicon {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: flex;
}

.topcontent {
	width: 100px;
	height: 100px;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	margin-right: 10px;
}

.shagnchuangtupian {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	position: relative;
}

.uploadcontent {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	background-color: #f5f5f5;
	position: relative;
	margin-right: 20px;
	margin-top: 10px;
}
.uploadcontent::after {
	position: absolute;
	content: '';
	width: 25px;
	height: 2px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.uploadcontent::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 25px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.up_text {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	white-space: nowrap;
	font-size: 13px;
	color: #999;
	z-index: 99;
}

.hello ::-webkit-scrollbar {
	display: none; /* Chrome Safari */
}

.successtext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
</style>
