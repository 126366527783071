<template>
	<div class="hello">
		<!-- <div class="zhanweifu"></div> -->

		<div class="top_content">
			<div class="left_icon" @click="gotoback">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">发布视频</div>
		</div>

		<!-- 		<div class="upload_box">
			<div class="item">
				<div class="itembg">
					<img src="../assets/upbg.png" alt="" />
				</div>
				<label class="top">
					上传文件
					<input @change="handleFileUpload" accept="video/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />
				</label>
				
			</div>
			<div class="item hengzhe" v-if="UserVid != 3">
				<p class="p1">上传视频数量</p>
				<p class="p2">{{ yhdata.UserUploadVideoCount }}/{{ yhdata.SurplusUploadVideoCount }}</p>
			</div>

			<div class="item hengzhe" v-else>
				<p class="p3">您是官方用户</p>
				<p class="p4">上传视频无限制</p>
			</div>
		</div> -->

		<div class="neirong">
			<div class="biaoti">
				<textarea v-model="pagebox.shortVideoTitle" maxlength="50" placeholder="写下合适的标题和上传优美的封面图,能获得更多推荐"></textarea>
			</div>
			<div class="fengmiantu" v-if="fngmianlianjie && upimglist.length > 0">
				<img class="fmimg" :src="uploadurl + '/' + fngmianlianjie" alt="" />
				<div class="xuanzhetupian" @click="fengmaintiaozhuan">选封面</div>
			</div>

			<div class="fengmiantu" v-if="!fngmianlianjie && upimglist.length > 0">
				<img class="fmimg" :src="upimglist[0]" alt="" />
				<div class="xuanzhetupian" @click="fengmaintiaozhuan">选封面</div>
			</div>

			<div class="sdsdsdsdsd" v-if="filedata == ''">
				<div class="sdsdbgbg">
					<img src="../assets/shouji.png" alt="" />
				</div>
				<label class="toptoptop">
					<input @change="handleFileUpload" accept="video/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />
				</label>
				<!-- <span id="addFileBtnSpan">{{ filedata.name }}</span> -->
			</div>
		</div>
		<div class="bqlist">
			<div class="xzbq_text" @click="xuanzhebiaoqian">
				<img src="../assets/biaoqian.png" alt="" />
				<span>选择标签(请点击)：</span>
			</div>

			<div class="ddfbox">
				<div class="bq_item" v-for="item in iditemvalue">
					<span>#</span>
					{{ item.LabelName }}
				</div>
			</div>
		</div>
		<div class="inputbox">
			<div class="item">
				<div class="title">
					<img src="../assets/jiage.png" alt="" />
					设置价格：

					<input v-model="pagebox.gold" class="inputcontent" type="number" />

					<!-- <span v-if="yhdata.HowUploadVideoCount >= 3">(再上传{{ yhdata.HowUploadVideoCount }}个视频，即可上传收费短视频)</span> -->
				</div>

				<!-- <input :disabled="yhdata.HowUploadVideoCount == 0 ? false : true" placeholder="金币不能大于10" v-model="pagebox.gold" class="inputcontent" type="number" /> -->
			</div>

			<!-- <div class="select_tag" @click="xuanzhebiaoqian">
				<div class="s_title">
					选择标签
					<span>(最多3个)</span>
				</div>
				<div class="s_icon">
					<img src="../assets/youjiantou.png" alt="" />
				</div>
			</div> -->

			<!-- <div class="tagboxbox">
				<div class="tablist">
					<div class="tab_item tab_current" v-for="item in iditemvalue">{{ item.LabelName }}</div>
				</div>
			</div> -->
		</div>

		<div class="tipsbox">
			<p>
				1.收益分成
				<span>50%</span>
				。
			</p>
			<p>
				2.为维护观看体验，视频价格不超过10
				<img src="../assets/jinbi_icon.png" alt="" />
				。
			</p>
			<!-- <p>2.创作者每上架{{ yhdata.HowUploadVideoCount }}个免费短视频,即可以上传1个收费短视频。</p> -->

			<p>3.每日最多上传10个视频，上传2个免费视频，即可上传一个收费视频。</p>

			<!-- <p style="white-space: nowrap" v-if="NoCheck != 1">
				3.您今天已上传<span>{{ yhdata.UserUploadVideoCount }}</span>个免费视频,最多可上传
				<span>{{ this.UserIc == 0 ? 5 : 10 }}</span>
				个免费视频, 还需要上传
				<span>{{ yhdata.HowUploadVideoCount }}</span>
				个视频才可以上传收费视频。
			</p -->

			<p>4.上传的文件大小不能超过800M。</p>
			<p>5.需要上传免费的视频才能上传收费的。</p>

			<p>6.您今日已上传{{ yhdata.UserUploadVideoCount }}个视频，最多可上传{{ yhdata.SurplusUploadVideoCount }}</p>

			<p v-if="yhdata.HowUploadVideoCount != 0">
				7.还需要上传
				<span style="color: red; padding: 0 4px">{{ yhdata.HowUploadVideoCount }}</span>
				部免费视频，即可上传收费视频。
			</p>

			<p style="color: red" v-if="yhdata.HowUploadVideoCount == 0">7.您当前可以上传收费视频</p>

			<p v-if="NoCheck == 1">
				8.您当前为官方账号，可
				<span>无限</span>
				上传视频，上传视频不受限制！
			</p>
		</div>

		<div class="upbtn" v-if="shangchuangstatus" @click="uplofile">发布视频</div>

		<div class="fabushortvideo" v-if="!shangchuangstatus" @click="fabuvideo(uploaddata)">发布视频</div>

		<div class="popus" v-if="showupbar">
			<p style="margin-bottom: 10px">等待上传中：{{ progressbar.toFixed(0) }}%</p>
			<img class="loadicon" src="../assets/loading.png" alt="" />
		</div>

		<div class="popus" v-if="zhuanmazhong">
			<p>正在上传中...</p>
		</div>

		<div class="uptipstext" v-if="selectvideotext">请选择视频。</div>

		<div class="uptipstext" v-if="texterrox">请输入视频信息。</div>
		<div class="successtext" v-if="upsuccess">上传成功,等待发布视频。</div>
		<div class="fabutext" v-if="fabusuccess">视频发布成功,等待审核!</div>
		<div class="errortext" v-if="uperror">上传失败。</div>
		<div class="errortext" v-if="upnum">今日上传剩余次数不足。</div>

		<div class="errortext" v-if="wenjiandaxiao">文件大小超出800M。</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			wenjiandaxiao: false,
			selecttext: '选择帖子板块',
			uploadstatus: true,
			showall: false,
			yhdata: '',
			currimgindex: 0,
			upimglist: [],
			uploadurl: 'https://videoupload.appinfo.ltd',
			baseurl: 'https://api.admin91.top',
			filedata: '',
			checked: false,
			checkboxs: [],
			UserId: '',
			Token: '',
			UserVid: '',
			UserIc: '',
			progressbar: 0,
			upnum: false,
			shangchuangstatus: true,
			showupbar: false,
			upsuccess: false,
			uperror: false,
			zhuanmazhong: false,
			fabusuccess: false,
			fabuzhuangtai: true,
			texterrox: false,
			selectvideotext: false,
			pagebox: {
				shortVideoTitle: '',
				videoImageURL: '',
				videoPreviewURL: '',
				videoM3U8URL: '',
				duration: '',
				upAndDown: false,
				labelId: '',
				gold: 0
			},
			uploaddata: '',
			total: 0,
			coverdata: [],
			coverurldata: '',
			fngmianlianjie: '',
			idboxboxbox: '',
			iditemvalue: '',
			NoCheck: 0
		};
	},

	watch: {
		'pagebox.gold': {
			handler: function (newValue, oldValue) {
				
				if (this.NoCheck == 0) {
					if (this.yhdata.HowUploadVideoCount == 0) {
						if (newValue > 10) {
							this.pagebox.gold = 0;
						}
					} else {
						this.pagebox.gold = 0;
					}
				}
			},
			deep: true
		}
	},

	mounted() {
		const params = new URLSearchParams(window.location.search);
		const Tokens = params.get('Token');
		const UserIds = params.get('UserId');
		const UserVids = params.get('UserVid');
		const UserIcs = params.get('UserIc');
		const NoChecks = params.get('NoCheck');
		this.UserId = UserIds;
		this.Token = Tokens;
		this.UserVid = UserVids;
		this.UserIc = UserIcs;
		this.NoCheck = NoChecks;
		this.getbiaoqian();
		this.uploaddetails();
	},

	activated() {
		//this.imglist = this.$route.params.imglist;
		if (this.$route.params.tupianlianjie) {
			this.fngmianlianjie = this.$route.params.tupianlianjie;
		}
		if (this.$route.params.ididids) {
			const shuju = this.$route.params.ididids;
			this.iditemvalue = this.$route.params.ididids;
			let idsssss = [];
			for (let index in shuju) {
				idsssss.push(shuju[index].LabelId);
			}
			this.idboxboxbox = idsssss.toString();
		}
	},

	methods: {
		gotoback() {
			webview.postMessage({
				data: '发布成功'
			});
		},
		//选择标签
		xuanzhebiaoqian() {
			this.$router.push({
				name: 'selecttag',
				params: {
					imglist: this.upimglist,
					UserId: this.UserId,
					Token: this.Token,
					UserVid: this.UserVid
				}
			});
		},

		//选择封面跳转页面
		fengmaintiaozhuan() {
			if (this.upimglist.length > 0) {
				this.$router.push({
					name: 'xzfengmian',
					params: {
						imglist: this.upimglist,
						UserId: this.UserId,
						Token: this.Token,
						UserVid: this.UserVid
					}
				});
			}
		},

		//获取视频标签
		getbiaoqian() {
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: this.UserId,
				Token: this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};
			axios.get(this.baseurl + '/api/ShortVideo/GetVedioLabels', { headers: header }).then((res) => {
				if (res.data.code == 200) {
					this.checkboxs = [];
					this.checkboxs = res.data.data;
				}
			});
		},

		//获取社区标签
		// getbiaoqian() {
		// 	let _this = this;
		// 	let header = '';
		// 	let InPutData = {
		// 		UserId: this.UserId,
		// 		Token: this.Token,
		// 		Type: '1'
		// 	};
		// 	header = {
		// 		InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
		// 	};
		// 	axios.get(this.baseurl + '/api/LongVideo/GetVedioLabels', { headers: header }).then((res) => {
		// 		if (res.data.code == 200) {
		// 			this.checkboxs = [];
		// 			for (let index in res.data.data) {
		// 				let obj = {
		// 					CreateTime: res.data.data[index].CreateTime,
		// 					LabelId: res.data.data[index].LabelId,
		// 					LabelImage: res.data.data[index].LabelImage,
		// 					LabelName: res.data.data[index].LabelName,
		// 					checked: false
		// 				};
		// 				this.checkboxs.push(obj);
		// 			}

		// 		}
		// 	});
		// },

		//获取用户上传信息
		uploaddetails() {
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: this.UserId,
				Token: this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};
			axios.get(this.baseurl + '/api/UserHandle/GetUserData', { headers: header }).then((res) => {
				if (res.data.code == 200) {
					this.yhdata = res.data.data;
				}
			});
		},

		//获取上传的文件
		handleFileUpload(e) {
			this.filedata = e.target.files[0];
			let chunkSize = 1 * 1024 * 1024; // 每个块的大小为 1MB
			if (e.target.files[0].size / chunkSize < 800) {
				this.uplofile();
			} else {
				this.filedata = '';
				this.wenjiandaxiao = true;
				setTimeout(() => {
					this.wenjiandaxiao = false;
				}, 1500);
			}
		},

		//对视频进行切片
		slicevideo(file) {
			return new Promise((resolve, reject) => {
				let chunkSize = 10 * 1024 * 1024; // 每个块的大小为 1MB
				let fileSize = file.size; // 文件大小
				let chunks = Math.ceil(fileSize / chunkSize); // 总块数
				let tasks = []; // 切块
				// 文件切割、
				for (let i = 0; i < chunks; i++) {
					let start = i * chunkSize;
					let end = Math.min(start + chunkSize, fileSize);
					tasks.push(file.slice(start, end));
				}
				resolve(tasks);
			});
		},

		//获取文件的md5值
		fileMd5Sum(file) {
			return new Promise((resolve) => {
				let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
				let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
				let fileReader = new FileReader();
				let Spark = new SparkMD5.ArrayBuffer();
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = function (e) {
					Spark.append(e.target.result);
					let md5 = Spark.end();
					let fileboxdata = {
						md5: md5,
						fileName: fileName,
						fileExtension: fileExtension
					};
					resolve(fileboxdata);
				};
			});
		},

		//大文件是否存在校验
		jianchefile(status, md5, uploadkey) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: status,
						md5: md5,
						uploadkey: uploadkey
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//大文件的分割的切片是否存在
		jianchefilesoon(chunkIndex, name, size) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunkCheck',
						chunkIndex: chunkIndex,
						name: name,
						size: size
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传视频分片
		upvideo(md5, index, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', index);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//合并视频切片
		joinvideo(md5, chunkslength, fileName, fileExtension) {
			let that = this;
			return new Promise((resolve, reject) => {
				let obj = {
					status: 'chunksMerge',
					md5: md5,
					chunks: chunkslength,
					name: md5,
					fileoldname: fileName,
					ext: fileExtension
				};
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunksMerge',
						md5: md5,
						chunks: chunkslength,
						name: md5,
						fileoldname: fileName,
						ext: fileExtension
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传图片文件
		upimgfile(md5, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', 0);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//上传文件
		async uplofile() {
			if (this.NoCheck != 1) {
				let bools = this.UserIc == 0 ? false : true;
				if (bools) {
					if (this.yhdata.UserUploadVideoCount >= 10) {
						this.upnum = true;
						setTimeout(() => {
							this.upnum = false;
						}, 2000);
						return;
					}
				} else {
					if (this.yhdata.UserUploadVideoCount >= 5) {
						this.upnum = true;

						return;
					}
				}
				// if (this.UserVid != 3) {
				// 	if (this.yhdata.UserUploadVideoCount >= this.yhdata.SurplusUploadVideoCount) {
				// 		this.upnum = true;
				// 		setTimeout(() => {
				// 			this.upnum = false;
				// 		}, 2000);
				// 		return;
				// 	}
				// }
			}
			if (!this.filedata) {
				this.selectvideotext = true;
				setTimeout(() => {
					this.selectvideotext = false;
				}, 2000);
				return;
			}
			if (this.uploadstatus) {
				this.uploadstatus = false;
				this.showupbar = true;
				//获取文件md5
				let md5mesgs = await this.fileMd5Sum(this.filedata);
				//对视频进行切片
				let splitvideo = await this.slicevideo(this.filedata);
				//检测视频是否存在 不存在就上传
				let fileres = await this.jianchefile('md5Check', md5mesgs.md5, 'sdqutyeksdbv855');
				for (let index in splitvideo) {
					//检测视频的分片 是否存在
					let slicefileres = await this.jianchefilesoon(index, md5mesgs.md5, splitvideo[index].size);
					let upres = await this.upvideo(md5mesgs.md5, index, splitvideo[index]);

					this.progressbar = (index / (splitvideo.length - 1)) * 100;

					if (index == splitvideo.length - 1 && upres.data == 'ok') {
						let hebingjieguo = await this.joinvideo(md5mesgs.md5, splitvideo.length, md5mesgs.fileName, md5mesgs.fileExtension);
						if (hebingjieguo.error != 'undefined' && hebingjieguo.error != undefined) {
							this.showupbar = false;
							this.uperror = true;
							this.uploadstatus = true;
							setTimeout(() => {
								this.uperror = false;
							}, 2000);
						} else {
							this.uploaddata = hebingjieguo;
							//选择封面图
							this.showupbar = false;
							this.zhuanmazhong = true;
							setTimeout(() => {
								this.uploadstatus = true;
								for (let i = 1; i <= 10; i++) {
									let url = this.uploadurl + hebingjieguo.rpath + '/' + i + '.jpg';
									this.upimglist.push(url);
								}

								this.zhuanmazhong = false;
								this.upsuccess = true;
								this.shangchuangstatus = false;
							}, 8000);
							setTimeout(() => {
								this.upsuccess = false;
							}, 10000);
						}
					}
				}
			}
		},

		//删除封面图
		delcoverdata() {
			this.coverdata = [];
			this.coverurldata = '';
		},

		//获取封面图
		handleimgfengmian(e) {
			const URL = window.URL || window.webkitURL; // 当前地址
			this.coverdata = [];
			this.coverdata.push(e.target.files[0]);
			this.coverurldata = URL.createObjectURL(e.target.files[0]);

			this.upfengmiantu();
		},

		//上传封面图
		async upfengmiantu() {
			//上传封面图
			if (this.coverdata) {
				const imglistfile = this.coverdata;
				for (let index in imglistfile) {
					//获取图片md5信息
					let imgmd5mesgs = await this.fileMd5Sum(imglistfile[index]);
					//检测视频是否存在 不存在就上传
					let imgfileres = await this.jianchefile('md5Check', imgmd5mesgs.md5, 'sdqutyeksdbv855');
					//上传图片
					let upimgres = await this.upimgfile(imgmd5mesgs.md5, imglistfile[index]);
					//合并上传图片
					let upimgjieguo = await this.joinvideo(imgmd5mesgs.md5, 1, imgmd5mesgs.fileName, imgmd5mesgs.fileExtension);
					if (upimgjieguo.error == 'undefined' || upimgjieguo.error == undefined) {
						let imageURL = upimgjieguo.url;
						let imagestr = imageURL.split('/');
						let imageresult = '';
						if (imagestr.length >= 3) {
							for (let i = 3; i < imagestr.length; i++) {
								if (imageresult !== '') {
									imageresult += '/';
								}
								imageresult += imagestr[i];
							}
						} else {
						}
						//this.pagebox.videoPreviewURL = imageresult;
						this.fngmianlianjie = imageresult;
					}
				}
			}
		},

		//选择图片
		handleselectimg(index) {
			this.currimgindex = index;
		},

		//选择标签
		handletab(itemvalue, index) {
			let checkboxs = this.checkboxs;
			for (let index in checkboxs) {
				const item = checkboxs[index].vedioList;
				for (let indexs in item) {
					if (itemvalue.LabelId == item[indexs].LabelId) {
						if (itemvalue.IsCheck) {
							this.total = this.total - 1;
							checkboxs[index].vedioList[indexs].IsCheck = !checkboxs[index].vedioList[indexs].IsCheck;
						} else {
							if (this.total < 3) {
								this.total = this.total + 1;
								checkboxs[index].vedioList[indexs].IsCheck = !checkboxs[index].vedioList[indexs].IsCheck;
							}
						}
					}
				}
			}
		},

		//发布短视频
		fabuvideo(itemvalue) {
			let _this = this;
			if (_this.fabuzhuangtai) {
				_this.fabuzhuangtai = false;

				if (!this.pagebox.shortVideoTitle) {
					this.texterrox = true;
					setTimeout(() => {
						this.texterrox = false;
					}, 2000);
					return;
				}

				let header = '';
				let InPutData = {
					UserId: _this.UserId,
					Token: _this.Token,
					Type: '1'
				};
				header = {
					InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
				};

				let m3u8url = itemvalue.url;
				let picurl = _this.upimglist[_this.currimgindex];
				let videourl = itemvalue.share;
				let m3u8str = m3u8url.split('/');
				let picstr = picurl.split('/');
				let videostr = videourl.split('/');
				let m3u8result = '';
				let picresult = '';
				let videoresult = '';
				if (m3u8str.length >= 3) {
					for (let i = 3; i < m3u8str.length; i++) {
						if (m3u8result !== '') {
							m3u8result += '/';
						}
						m3u8result += m3u8str[i];
					}
					for (let h = 3; h < picstr.length; h++) {
						if (picresult !== '') {
							picresult += '/';
						}
						picresult += picstr[h];
					}
					for (let j = 3; j < videostr.length; j++) {
						if (videoresult !== '') {
							videoresult += '/';
						}
						videoresult += videostr[j];
					}
				} else {
				}

				axios
					.post(
						_this.baseurl + '/api/ShortVideo/PublishShortVideo',
						{
							shortVideoTitle: _this.pagebox.shortVideoTitle,
							videoImageURL: _this.fngmianlianjie == '' || _this.fngmianlianjie == undefined ? picresult : _this.fngmianlianjie,
							videoPreviewURL: videoresult,
							videoM3U8URL: m3u8result,
							duration: itemvalue.duration,
							upAndDown: false,
							labelId: _this.idboxboxbox,
							gold: _this.pagebox.gold == '' ? 0 : _this.pagebox.gold
						},
						{
							headers: header
						}
					)
					.then((res) => {
						if (res.data.code == 200) {
							_this.fabusuccess = true;
							setTimeout(() => {
								_this.fabusuccess = false;
							}, 2000);
							setTimeout(() => {
								webview.postMessage({
									data: '发布成功'
								});
							}, 1500);
						} else {
							_this.fabuzhuangtai = true;
							_this.uperror = true;
							setTimeout(() => {
								_this.uperror = false;
							}, 2000);
						}
					})
					.catch((error) => {});
			}
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.hello {
	background-color: #090216;
	min-height: 100vh;
	width: 375px;
}

.top_content {
	height: 40px;
	display: flex;
	align-items: center;
	padding-top: 25px;
	flex-direction: row;
	padding-left: 10px;
}
.left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.left_icon img {
	width: 20px;
	height: 20px;
}

.center_content {
	font-size: 20px;
	font-weight: bold;
	padding-left: 110px;
	color: #fff;
}
.rules {
	font-size: 14px;
	position: absolute;
	right: 20px;
	bottom: 10px;
	color: blue;
	font-weight: bold;
}
.zhanweifu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 40px;
	background-color: #f5f5f5;
	z-index: 999;
}
.upload_box {
	display: flex;
	justify-content: space-around;
}
.upload_box .item {
	width: 160px;

	border: 1px dashed #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
}
.upload_box .itembg {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.upload_box .itembg img {
	width: 40px;
	height: 40px;
}
#addFileBtnSpan {
	font-size: 14px;
	margin-top: 8px;
	width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	font-size: 12px;
}
.upload_box .hengzhe {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.upload_box .hengzhe p {
	font-size: 16px;
}

.upload_box .hengzhe .p1 {
	color: #000;
	margin-bottom: 10px;
}
.upload_box .hengzhe .p2 {
	color: #999;
}

.upload_box .hengzhe .p3 {
	color: #000;
	margin-bottom: 10px;
	font-size: 16px;
}
.upload_box .hengzhe .p4 {
	color: #999;
	font-size: 12px;
}

.top {
	width: 160px;
	height: 50px;
	opacity: 0;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	border: 1px solid #666666;
}
.topFile {
	height: 30px;
	width: 95px;
	background-color: floralwhite;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	border-radius: 5px;
	margin-left: 15px;
}

.neirong {
	display: flex;
	padding: 60px 10px 0 0px;
}
.neirong .biaoti {
	margin-left: 10px;
}
.biaoti textarea {
	height: 100px;
	width: 210px;
	padding: 5px;
	border: none;
	outline: none;
	resize: none;
	font-size: 15px;
	background: none;
	border: 1px solid #fff;
	margin-left: 5px;
	border-radius: 10px;
	color: #fff;
}

textarea::-webkit-input-placeholder {
	color: #fff;
}
.neirong .fengmiantu {
	width: 100px;
	height: 130px;

	margin-left: 10px;
	position: relative;
}

.sdsdsdsdsd {
	width: 120px;
	height: 110px;
	position: relative;
	border-left: none;
}

.sdsdsdsdsd .sdsdbgbg {
	width: 95px;
	height: 120px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.sdsdsdsdsd .sdsdbgbg img {
	width: 95px;
	height: 120px;
}

.bqlist {
	align-items: center;
	padding-left: 0px;
	margin-left: 10px;
	margin-top: 10px;
}
.ddfbox {
	display: flex;
}
.bqlist .bq_item {
	margin-top: 10px;
	font-size: 15px;
	padding: 4px 8px;
	border-radius: 5px;
	font-weight: bold;
	margin-right: 10px;
	background: #21182e;
	border: 1px solid #ad84b3;
	color: #fff;
}
.xzbq_text {
	display: flex;
	align-items: center;
}
.xzbq_text span {
	font-size: 16px;
	color: #999;
}
.xzbq_text img {
	width: 15px;
	height: 15px;
	margin-left: 2px;
	margin-right: 3px;
}
.toptoptop {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.fmimg {
	width: 100px;
	height: 130px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-radius: 5px;
}
.xuanzhetupian {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 13px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
}

.inputbox {
	padding-left: 10px;
	margin-top: 15px;
}
.inputbox .item {
	padding: 10px 0px 5px 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.inputbox .item .title {
	font-size: 16px;
	text-align: left;
	color: #fff;

	position: relative;
	display: flex;
	align-items: center;
}
.inputbox .item .title img {
	width: 17px;
	height: 17px;
	margin-right: 1px;
}

.inputbox .item .title span {
	font-size: 12px;
	color: #fff;
	font-weight: normal;
}

.select_tag {
	margin-top: 15px;
	padding-top: 15px;
	border-top: 1px solid #eee;
	height: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.select_tag .s_title {
	font-size: 16px;
	text-align: left;
	color: #fff;
	font-weight: bold;
	padding-left: 15px;
	position: relative;
}
.select_tag .s_title span {
	font-size: 12px;
	color: #999;
	font-weight: normal;
}

.select_tag .s_title::after {
	content: '';
	position: absolute;
	left: 8px;
	top: 4px;
	height: 13px;
	width: 2px;
	background-color: red;
}

.select_tag .s_icon {
	width: 15px;
	height: 15px;
	display: flex;
}

.select_tag .s_icon img {
	width: 15px;
	height: 15px;
}

.inputcontent {
	width: 60px;
	height: 25px;
	margin-left: 5px;
	padding-left: 10px;
	border: 1px solid #eee;
	border-radius: 20px;
	background-color: #f5f5f5;
	color: green;
}

.biaoqian {
	height: 35px;
	background-color: #eee;
	margin-left: 5px;
	margin-right: 15px;
	border-radius: 5px;

	position: relative;
	span {
		position: absolute;
		left: 15px;
		font-size: 13px;
		top: 50%;
		transform: translateY(-50%);
		color: #fff;
	}
	.biaoqiaoleft {
		position: absolute;
		width: 20px;
		height: 20px;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		img {
			width: 20px;
			height: 20px;
		}
	}
}

.tipsbox {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	width: 350px;
}
.tipsbox p {
	font-size: 13px;
	color: #fff;
	text-align: left;
	line-height: 23px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 6px;
}

.tipsbox p span {
	color: green;
	font-size: 17px;
	font-weight: bold;
}

.tipsbox p img {
	width: 12px;
	height: 12px;
	margin-left: 2px;
	display: flex;
}

.upbtn {
	height: 44px;
	border-radius: 16px;
	width: 331px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 100px auto 0 auto;

	background: linear-gradient(90deg, #ff5f3a, #ff1c1c);
}
.fabushortvideo {
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	height: 44px;
	border-radius: 16px;
	width: 331px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 16px auto 0 auto;
	background-image: linear-gradient(#04df97, #00b3da);
}

.tagboxbox {
	display: flex;
	flex-direction: column;
}
.tagboxbox .title {
	text-align: left;
	font-size: 14px;
	font-weight: bold;
	color: red;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-top: 10px;
}
.tablist {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 5px;
	overflow-y: hidden;
	/* height: 263px; */
}
.tablistall {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 5px;
	overflow-y: hidden;
	height: auto;
}
.tablisthiden {
	height: 265px;
}
.tablist .tab_item {
	padding: 0 7px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 5px;
	font-size: 12px;
	text-align: center;
	border: 1px solid #eee;
	margin-right: 5px;
	margin-top: 6px;
}
.tablist .tab_current {
	background-color: gold;
	color: #fff;
}
.zhankai {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 13px;
	flex-direction: row;
	margin: 10px auto 0 auto;
}
.zhankai span {
	width: 12px;
	height: 12px;
	display: flex;
	margin-right: 4px;
}
.zhankai span img {
	width: 12px;
	height: 12px;
}
.uplist {
	display: flex;
	flex-wrap: wrap;
}
.uplist .up_item {
	width: 60px;
	height: 100px;
	margin-top: 10px;
	margin-left: 5px;
	border: 1px solid #fff;
}
.uplist .select_item {
	border: 1px solid red;
}
.uplist .up_item img {
	width: 60px;
	height: 100px;
}

.popus {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 16px;
	flex-direction: column;
}
.popus .loadicon {
	width: 40px;
	height: 40px;
	-webkit-transform: rotate (360deg);
	animation: rotation 1s linear infinite;
	-moz-animation: rotation 1s linear infinite;
	-webkit-animation: rotation 1s linear infinite;
	-o-animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

.popus .jingdutiao {
	width: 250px;
	height: 12px;
	border: 1px solid #fff;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
.popus .jingdutiao span {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: gold;
}
.transcoding {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.uptipstext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.successtext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.fabutext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.errortext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}

.sc_boxboxbox {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}
.imglistbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox .img_item {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
}
.imglistbox .img_item img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}
.shanchuicon {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: flex;
}

.topcontent {
	width: 100px;
	height: 100px;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
}

.uploadcontent {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	background-color: #f5f5f5;
	position: relative;
	margin-right: 20px;
}
.uploadcontent::after {
	position: absolute;
	content: '';
	width: 25px;
	height: 2px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.uploadcontent::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 25px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}

.up_text {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	white-space: nowrap;
	font-size: 13px;
	color: #999;
	z-index: 99;
}
.selected_video {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	white-space: nowrap;
	font-size: 12px;
	z-index: 99;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 80px;
	color: red;
}

textarea::-webkit-input-placeholder {
	color: #999;
}
input::-webkit-input-placeholder {
	color: #999;
}
</style>
