import { render, staticRenderFns } from "./vploadvideo.vue?vue&type=template&id=58c8f60c&scoped=true"
import script from "./vploadvideo.vue?vue&type=script&lang=js"
export * from "./vploadvideo.vue?vue&type=script&lang=js"
import style0 from "./vploadvideo.vue?vue&type=style&index=0&id=58c8f60c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c8f60c",
  null
  
)

export default component.exports